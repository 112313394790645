import { reactive } from 'vue'
import useHttp from './useHttp'

import { parsePage } from '../utils/utilities'

export default () => {
  const fetchData = reactive({
    count: 0,
    current: null,
    next: null,
    previous: null,
    results: [],
    params: {}
  })

  const http = useHttp()

  const createMenu = async data => await http.exec({ method: 'POST', url: '/menu/create/', data })

  const deleteMenu = async id => {
    await http.exec({ method: 'DELETE', url: `/menu/${id}/` })
    const { count, current } = fetchData
    let page = (count - 1) % 10 ? current : current - 1
    page = page > 0 ? page : 1 // Do not allow 0 page value
    await fetchMenus({ ...fetchData.params, page })
  }

  const fetchMenus = async params => {
    const resp = await http.exec({ method: 'GET', url: '/menu/list/', params })
    if (!http.error.value) {
      const data = parsePage(resp.data)
      Object.assign(fetchData, { ...data, params })
      return { data, params }
    } else return null
  }

  const fetchCostCenterOptions = async params =>
    await http.exec({ method: 'GET', url: '/menu/cost-center-options/', params })

  const getMenu = async id => {
    const resp = await http.exec({ method: 'GET', url: `/menu/${id}/` })
    return resp?.data
  }

  const updateMenu = async data => await http.exec({ method: 'PUT', url: `/menu/${data.id}/`, data })

  return { ...http, createMenu, deleteMenu, fetchData, fetchCostCenterOptions, fetchMenus, getMenu, updateMenu }
}
