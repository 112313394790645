<template>
  <div class="p-grid p-jc-center p-ai-stretch vertical-container">
    <div class="p-col-12">
      <h4>{{ menu?.title }}</h4>
      <div
        id="embed-container"
        class="flat p-d-flex p-flex-column p-ai-center p-jc-center p-mt-3"
        v-html="menu?.content"
      ></div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import useMenu from '../../hooks/useMenu'

export default {
  name: 'MenuContent',
  props: { menuId: { type: [String, Number], required: true } },
  setup(props) {
    const { data: useMenuData, getMenu } = useMenu()

    const menu = ref(null)

    const stop = watch(useMenuData, (value) => {
      if (value) {
        menu.value = value
      }
    })

    onMounted(async () => {
      await getMenu(props.menuId)

      const iframe = document.querySelector('#embed-container > iframe')
      if (!iframe) return
      if (!iframe.hasAttribute('width')) iframe.setAttribute('width', '100%')
      if (!iframe.hasAttribute('height')) {
        const container = document.getElementById('embed-container')
        container.setAttribute('style', 'height: 80vh')
        iframe.setAttribute('height', '100%')
      }
      stop()
    })
    return { menu }
  }
}
</script>

<style scoped>
</style>
